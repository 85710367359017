import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./pages/Home";
import './index.css';
import Tappo from "./pages/Tappo";

const router = createBrowserRouter([
    { path: '/register', element: <Home/>},
    { path: '/', element: <Home/>}
])

function App() {
  return <RouterProvider router={router} />;
}

export default App;
