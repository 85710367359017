import mainStyle from './Main.module.css';

import tappoImage from '../assets/various/Tappo_landing-page.jpg';

function Tappo() {
    return (
        <div style={{width:'100vw', height:'100vh', overflow:'hidden'}} className={mainStyle.tappoContainer}>

        </div>
    )
}

export default Tappo;