import agendaStyle from './Agenda.module.css'
import agendaIcon from '../assets/various/agenda-icon.svg'
function Agenda() {
    return(
        <div className={agendaStyle.mainContainer}>
            <div className={agendaStyle.headerContainer}>
                <div className={agendaStyle.titleContainer}>
                    <h1 style={{fontWeight: 'normal', margin:0, padding:'16px'}}>Agenda</h1>
                </div>
            </div>
            <div className={agendaStyle.agendaContainer}>
                <div className={agendaStyle.leftColumn}>
                    <h2 className={agendaStyle.title}>14:00 - 15:00</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>Accredito e Welcome Coffee</h2>

                    <h2 className={agendaStyle.title}>15:00 - 15:20</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>Opening Speech </h2>
                    <h2 className={agendaStyle.subtitle}>Fabrizio Ruggiero</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontStyle: 'italic'}}>Amministratore
                        Delegato <br/> Edenred Italia</h2>

                    <h2 className={agendaStyle.title}>15:20 - 15:40</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>Special guest</h2>
                    <h2 className={agendaStyle.subtitle}>Gianluca Gotto</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontStyle: 'italic'}}>Scrittore</h2>

                    <h2 className={agendaStyle.title}>15:40 - 16:00</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>E-Talk</h2>
                    <h2 className={agendaStyle.subtitle}>Virginia Gambella</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontStyle: 'italic'}}>Responsabile People Care <br/>
                        Autostrade per l’Italia</h2>

                    <h2 className={agendaStyle.title}>16:00 - 16:20</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>Special guest</h2>
                    <h2 className={agendaStyle.subtitle}>Francesca Florio</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontStyle: 'italic'}}>Avvocato e Divulgatrice di diritto</h2>
                </div>
                <div className={agendaStyle.rightColumn}>
                    <h2 className={agendaStyle.title}>16:20 - 16:40</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>E-Talk</h2>
                    <h2 className={agendaStyle.subtitle}>Giacomo Strazza</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontStyle: 'italic'}}>Chief Human Resources Officer
                        SIAE</h2>

                    <h2 className={agendaStyle.title}>16:40 - 17:10</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>Coffee Break</h2>

                    <h2 className={agendaStyle.title}>17:10 - 17:30</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>Speech</h2>
                    <h2 className={agendaStyle.subtitle}>Giulio Siniscalco</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontStyle: 'italic'}}>Commercial Director Benefit &
                        Engagement Edenred Italia</h2>

                    <h2 className={agendaStyle.title}>17:30 - 18:20</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>Roundtable Discussion</h2>
                    <h2 className={agendaStyle.subtitle}>Welfare aziendale e lavoro:
                        trend, sviluppi e soluzioni</h2>

                    <h2 className={agendaStyle.title}>18:20 - 18:30</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>Closing</h2>

                    <h2 className={agendaStyle.title}>18:30 - 21:00</h2>
                    <h2 className={agendaStyle.subtitle} style={{fontWeight: 'bold'}}>Light Aperitif & Networking</h2>
                </div>
            </div>

        </div>
    )
}

export default Agenda;