import footerStyle from './Footer.module.css'

import landscape from '../assets/header/Landscape_1.svg'
import footerImg from '../assets/various/footer.svg'
function Footer() {
    return(
        <div>
            <img className={footerStyle.landscape} src={landscape} alt={''}/>
            <div className={footerStyle.mainContainer}>
                <img className={footerStyle.footerImg} src={footerImg} alt={''}/>
                <div className={footerStyle.textContainer}>
                    <div className={footerStyle.textBlock}>
                        <h2 style={{marginBottom:0}} className={footerStyle.title}>Welfare Forum 2024:</h2>
                        <h3 style={{marginTop:0, fontWeight:'normal'}} className={footerStyle.subtitle}>The insiders</h3>
                        <p className={footerStyle.paragraph}>
                            Per qualsiasi informazione o richiesta è attiva la nostra segreteria organizzativa <a href="mailto:segreteria@edenredwelfareforum.it" style={{color:'white'}}>segreteria@edenredwelfareforum.it </a>
                        </p>
                        <h3 className={footerStyle.subtitle} style={{marginBottom:'1vh'}}>edenred.it</h3>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer;