import headerStyle from './Header.module.css';
import mainStyle from './Main.module.css';
import formStyle from './Form.module.css';
import infoStyle from './Info.module.css';
import peopleStyle from './People.module.css';
import speakerStyle from './Speakers.module.css';
import locationStyle from './Location.module.css';


import {
    CustomCheckbox,
    CustomTextField,
    CustomCheckBoxOutlineBlankIcon,
    CustomCheckBoxIcon,
    CustomButton,
    CustomFormControlLabel
} from '../mui-styles/styles';

import headerGif from '../assets/header/Edenred_WF.gif'
import headerImg from '../assets/header/Landscape_1.svg';
import spiral1 from '../assets/various/spiral-1.svg';
import spiral2 from '../assets/various/spiral-2.svg';
import miaCeran from '../assets/people/mia-ceran.png';
import davideDattoli from '../assets/people/davide-dattoli.png';
import gianlucaGotto from '../assets/people/gianluca-gotto.png';
import francescaFlorio from '../assets/people/francesca-florio.png';
import silviaWang from '../assets/people/silvia-wang.png';
import giuseppeBertuccio from '../assets/people/giuseppe-bertuccio.png'

import fabrizioRuggiero from '../assets/people/fabrizio-ruggiero.png';
import giulioSiniscalco from '../assets/people/giulio-siniscalco.png'
import stefaniaRausa from '../assets/people/stefania-rausa.png';
import landscapeRed from '../assets/various/landscape-red.svg';

import locationImg1  from '../assets/location/location-img-1.png'
import locationImg2  from '../assets/location/location-img-2.png'

import {
    Alert, AlertTitle, Box, CircularProgress,
    Grid, Typography
} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import Agenda from "../components/Agenda";
import Footer from "../components/Footer";
import axios from "axios";
import Sponsors from "../components/Sponsors";

function Home() {
    // Stato per registrare la provenienza dell'utente (Linkedin, Instagram, ecc)
    const [source, setSource] = useState('');
    // Stati per i campi del form
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [azienda, setAzienda] = useState('');
    const [cittaAzienda, setCittaAzienda] = useState('');
    const [cap, setCap] = useState('');

    // Stati per le caselle di controllo
    const [privacyAccepted, setPrivacyAccepted] = useState(false);

    const [marketingConsent, setMarketingConsent] = useState(false);

    // Stato per gli errori
    const [errors, setErrors] = useState(
        { privacyAccepted: false },
        { requiredField: false },
        {emailInvalid: false},
        {phoneInvalid: false}
    );

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submissionError, setSubmissionError] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    //Regex per mail e telefono
    const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const phoneRegExp = /^\+?([0-9]{1,3})?[-. ]?([0-9]{2,4})[-. ]?([0-9]{2,4})[-. ]?([0-9]{2,4})$/;

    const myDivToFocus = useRef(null);
    const redSection = useRef(null);

    const API_URL = 'https://edenred-welfare-forum-api-23-941629400866.europe-west1.run.app/register';

    useEffect(() => {
        getUTMdiv();
        // Capture the UTM source when the component mounts
        const utmSource = getUTMParameter('utm_source');
        if(utmSource) {
            setSource(utmSource);
        }

    }, []);

    function getUTMParameter(paramName) {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(paramName);
    }

    function getUTMdiv() {
        const searchParams = new URLSearchParams(window.location.search);
        const scroll_to = searchParams.get('scroll_to');
        if (scroll_to) {
            redSection.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    // Gestione dell'invio del form
    const handleSubmit = (event) => {
        event.preventDefault(); // Previene il comportamento di invio predefinito del form

        //Controllo errori
        if (!privacyAccepted ||
            nome==='' ||
            cognome==='' ||
            email==='' ||
            telefono==='' ||
            jobTitle==='' ||
            azienda==='' ||
            cittaAzienda===''||
            cap==='') {
            // Imposta l'errore per privacyAccepted
            setErrors({ ...errors, requiredField: true, emailInvalid: false, phoneInvalid: false });
            return; // Impedisce l'invio del form
        }

        if(!emailRegExp.test(email)) {
            setErrors({ ...errors, emailInvalid: true, requiredField: false, phoneInvalid:false });
            return;
        }

        if(!phoneRegExp.test(telefono)) {
            setErrors({ ...errors, phoneInvalid: true , requiredField: false, emailInvalid: false});
            return;
        }

        setErrors({ ...errors, emailInvalid: false, requiredField: false, phoneInvalid:false });
        setIsLoading(true);
        // Crea l'oggetto JSON con i dati del form
        const formData = {
            nome,
            cognome,
            email,
            telefono,
            jobTitle,
            azienda,
            cittaAzienda,
            cap,
            privacyAccepted,
            marketingConsent,
            origin: (source === '' ? 'unknown' : source)
        };

        // Invio di formData
        console.log('Dati del form:', formData);
        axios.post(API_URL, formData, {
            headers: {
                // Set any headers as needed
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                // Handle success
                console.info('Success:', response.data);
                setFormSubmitted(true);
                setSubmissionError('');
            })
            .catch(error => {
                // Handle error
                console.info(error?.response?.data?.error)
                setSubmissionError(error?.response?.data?.error);
            })
            .finally(() => {
                    setIsLoading(false); // Nasconde l'indicatore di caricamento
                });
    };

    useEffect(() => {
        // Show the alert when there is a submission error
        if (!!submissionError) {
            setShowAlert(true);

            // Set a timer to hide the alert after 5 seconds
            const timer = setTimeout(() => {
                setShowAlert(false);
                setSubmissionError('');
            }, 5000);

            // Clear the timer if the component unmounts or if the error changes before the timer fires
            return () => clearTimeout(timer);
        }
    }, [submissionError]);

    const scrollToForm = () => {
        myDivToFocus.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    return (
        <div className={mainStyle.mainContainer}>
            {/* HEADER PART */}
            <div className={headerStyle.header}>
                <img src={headerGif} alt={'header'} className={headerStyle.gif}/>
                <img src={headerImg} alt={'landscape'} className={headerStyle.landscape}/>
                <div className={headerStyle.infoPanel}>
                    <h1 className={headerStyle.infoText}>
                        <span style={{fontWeight: '500'}}>28 ottobre</span>
                        <span style={{fontWeight: '300'}}>, </span>
                        <span style={{fontWeight: '500'}}>h.14:30</span>
                        <span style={{fontWeight: '300'}}>, </span>
                        <span style={{fontWeight: '300'}}>Forum Theatre - Roma</span>
                    </h1>
                </div>
            </div>
            <div ref={myDivToFocus}>

            </div>
            {/* FORM PART */}
            {!formSubmitted &&
                <div className={formStyle.mainContainer} >
                    <h1 className={formStyle.mainText}>
                        <span style={{fontWeight: '500'}}>Compila il form e registrati</span>
                    </h1>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CustomTextField
                                    label="Nome*"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    label="Cognome*"
                                    value={cognome}
                                    onChange={(e) => setCognome(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    label="E-mail*"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    label="N. di Telefono*"
                                    value={telefono}
                                    onChange={(e) => setTelefono(e.target.value)}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    label="Job Title*"
                                    value={jobTitle}
                                    onChange={(e) => setJobTitle(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    label="Azienda*"
                                    value={azienda}
                                    onChange={(e) => setAzienda(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    label="Città azienda*"
                                    value={cittaAzienda}
                                    onChange={(e) => setCittaAzienda(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    label="CAP*"
                                    value={cap}
                                    onChange={(e) => setCap(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <div className={formStyle.privacyCheck}>
                            <CustomFormControlLabel
                                control={<CustomCheckbox
                                    checked={privacyAccepted}
                                    onChange={(e) => setPrivacyAccepted(e.target.checked)}
                                    icon={<CustomCheckBoxOutlineBlankIcon/>}
                                    checkedIcon={<CustomCheckBoxIcon/>}
                                />}
                                label={
                                    <span className={formStyle.checkboxLabel}>
                                    Ho letto e compreso {' '}
                                        <a
                                            href="https://repo.edenred.it/temp/Welfare%20Forum%20Roma/Informativa%20privacy_evento%20Welfare%20Forum%20Roma_28%20ottobre%202024.pdf"
                                            target="_blank"
                                            style={{textDecoration: 'underline', color: 'blue'}}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                    l'informativa privacy
                                    </a>
                                    *
                                </span>
                                }
                            />
                            <p style={{margin: 0}}> *campo obbligatorio</p>
                            <CustomFormControlLabel
                                control={<CustomCheckbox
                                    checked={marketingConsent}
                                    onChange={(e) => setMarketingConsent(e.target.checked)}
                                    icon={<CustomCheckBoxOutlineBlankIcon/>}
                                    checkedIcon={<CustomCheckBoxIcon/>}
                                />}
                                label={<span className={formStyle.checkboxLabel}>Presto il consenso a ricevere comunicazioni di marketing diretto.</span>}
                            />


                            <span>
                                Si informa che la partecipazione all’Evento prevede la registrazione di foto e video della mia
                                immagine e che il materiale fotografico e video realizzato potrà essere diffuso, in tutto o in parte,
                                sui siti web aziendali e sui social network aziendali. <br/>
                                <a
                                    href="https://repo.edenred.it/temp/Welfare%20Forum%20Roma/Liberatoria%20immagini_evento%20Welfare%20Forum%20Roma_28%20ottobre%202024.pdf"
                                    target="_blank"
                                    style={{textDecoration: 'underline', color: 'blue'}}
                                    onClick={(e) => e.stopPropagation()}
                                >Informativa completa</a>
                            </span>


                        </div>

                        <div style={{
                            height: '5vh',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '0vh',
                            color: '#f12218',
                            textAlign: 'center'
                        }}>
                            {errors.requiredField &&
                                <h3 className={formStyle.errorMessage}>Per favore, compila correttamente tutti i campi
                                    del form.</h3>}
                            {errors.emailInvalid &&
                                <h3 className={formStyle.errorMessage}>La mail che hai inserito non è valida.</h3>}
                            {errors.phoneInvalid &&
                                <h3 className={formStyle.errorMessage}>Il numero di telefono che hai inserito non è
                                    valido.</h3>}
                        </div>
                        <div style={{
                            height: '5vh',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '0vh',
                            color: '#f12218',
                            textAlign: 'center'
                        }}>

                                <h3 className={formStyle.errorMessage}>Le iscrizioni sono chiuse</h3>

                        </div>

                        <div className={formStyle.buttonContainer}>
                            {isLoading ? (
                                <CircularProgress sx={{color: '#f12218'}}/>
                            ) : (
                                <CustomButton
                                    type="submit"
                                    disabled={true}
                                >
                                    Registrati
                                </CustomButton>
                            )}

                        </div>

                    </form>
                </div>
            }
            {formSubmitted &&
                <div className={formStyle.mainContainer}>
                    <h1 className={formStyle.successMessage}>Grazie per esserti registrato! <br/> A breve riceverai una
                        mail di conferma</h1>
                </div>
            }
            {showAlert && submissionError &&
                <div className={mainStyle.alertContainer}>
                    <Alert variant="filled" severity="error">{submissionError}</Alert>
                </div>
            }


            {/* SEZIONE ROSSA + INFO */}
            <div className={infoStyle.redSection} id={'redSection'} ref={redSection}>
                <img src={spiral1} alt={''} className={infoStyle.redSectionSpiral1}/>
                <img src={spiral2} alt={''} className={infoStyle.redSectionSpiral2}/>
                <div className={infoStyle.redSectionText}>
                    <p>
                        Il <span className={infoStyle.grassetto}>Forum Theatre</span> ospiterà la nuova edizione romana del <span className={infoStyle.grassetto}>Welfare Forum</span>,
                        l’evento annuale dedicato al welfare aziendale e alle risorse umane.
                        Sul palcoscenico di questo prestigioso teatro, insieme agli attori del settore,
                        metteremo in luce le tematiche attuali del <span className={infoStyle.grassetto}>mondo del lavoro</span>: dalle risposte ai
                        <span className={infoStyle.grassetto}> nuovi bisogni</span> delle <span className={infoStyle.grassetto}>persone</span>, alle <span className={infoStyle.grassetto}>novità normative</span> e ai futuri scenari del <span className={infoStyle.grassetto}>welfare</span>.
                    </p>
                </div>
            </div>
            <div className={infoStyle.landscapeSection}>
                <div className={infoStyle.landscapeSectionText}>
                    <div className={infoStyle.landscapeSectionItem}>
                        <h1 className={infoStyle.landscapeSectionRedText}>+200</h1>
                        <h3 className={infoStyle.landscapeSectionBlackText}>Direttori HR</h3>
                    </div>
                    <div className={infoStyle.landscapeSectionItem} >
                        <h1 className={infoStyle.landscapeSectionRedText}>+10</h1>
                        <h3 className={infoStyle.landscapeSectionBlackText}>Guest</h3>
                    </div>
                </div>
            </div>
                {/* SEZIONE PERSONE */}
            <div className={peopleStyle.mainContainer}>
                <div className={peopleStyle.personContainer}>
                    <div className={peopleStyle.imageContainer}>
                        <img src={miaCeran} alt={''} className={peopleStyle.personImage}/>
                    </div>

                    <div className={peopleStyle.textContainerLeft}>
                        <h2 className={peopleStyle.personName}>Mia Ceran</h2>
                        <p className={peopleStyle.personInfo}>
                            Giornalista professionista e conduttrice televisiva attenta ai temi economici politici e
                            sociali. Voce narrante e autrice di “The Essential”, il più ascoltato podcast di news in
                            Italia.
                        </p>
                    </div>
                </div>
                <div className={peopleStyle.personContainer}>
                    <div className={peopleStyle.textContainerRight}>
                        <h2 className={peopleStyle.personName}>Gianluca Gotto</h2>
                        <p className={peopleStyle.personInfo}>
                            A vent'anni lascia l'Italia per trasferirsi prima in Australia e poi in Canada. Da sempre appassionato di viaggi, libri e spiritualità, dal 2015 lavora in remoto con la sua passione per la scrittura e vive viaggiando, soprattutto in Oriente. Sul suo blog e nei suoi libri condivide spunti, aneddoti, lezioni zen e strumenti per vivere una vita piena di significato e saggezza, ma anche più serena e leggera. Parla di benessere a 360 gradi: da un lato il coraggio di cambiare per essere felici e realizzarsi, dall'altro la serenità interiore che ha scoperto attraverso il buddhismo e la meditazione.
                        </p>
                    </div>
                    <div className={peopleStyle.imageContainer}>
                        <img src={gianlucaGotto} alt={''} className={peopleStyle.personImage}/>
                    </div>
                </div>
                <div className={peopleStyle.personContainer}>
                    <div className={peopleStyle.imageContainer}>
                        <img src={francescaFlorio} alt={''} className={peopleStyle.personImage}/>
                    </div>

                    <div className={peopleStyle.textContainerLeft}>
                        <h2 className={peopleStyle.personName}>Francesca Florio</h2>
                        <p className={peopleStyle.personInfo}>
                            Avvocato penalista, divulgatrice di diritto e head of the department of criminal law dello studio legale Wildside Human First. Autrice del libro “Non Chiamatelo Revenge Porn” edito da Mondadori nel 2022, è stata inserita nella Lista Forbes talenti italiani under 30 - 2024.
                        </p>
                    </div>
                </div>
            </div>
            {/* SEZIONE SPEAKERS */}
            <div className={speakerStyle.mainContainer}>
                <div className={speakerStyle.header}>
                    <div className={speakerStyle.headerTitle}>
                        <h1 className={speakerStyle.titleText}>
                            Speaker
                        </h1>
                    </div>
                    <img src={landscapeRed} alt={'landscape'} className={speakerStyle.landscape}/>
                </div>
                {/*<div className={speakerStyle.speakerContainer1}>
                    <img src={fabrizioRuggiero} alt={'landscape'} className={speakerStyle.speakerImg1}/>
                </div>
                <div className={speakerStyle.speakerInfoContainer1}>
                    <div className={speakerStyle.speakerInfo}>
                        <h3 className={speakerStyle.speakerName}>
                            Fabrizio Ruggiero
                        </h3>
                        <p className={speakerStyle.speakerP}>
                            Direttore Generale e Amministratore Delegato di Edenred Italia. Laureato presso l’Università
                            la Sapienza di Roma in Economia Aziendale e con un Master in Business Administration al MIP,
                            vanta importanti esperienze nei settori “Leisure, Travel & Transportation” e “Business &
                            Financial Services”. Inizia la sua carriera come Consulente prima in Accenture e
                            successivamente in Bain & Company per poi approdare nel 2011 in Europcar Mobility Group come
                            Amministratore Delegato e Direttore Generale Italia.
                        </p>
                    </div>
                </div>*/}
                <div className={speakerStyle.speakerContainer}>
                    <img src={fabrizioRuggiero} alt={'landscape'} className={speakerStyle.speakerImg}/>
                    <img src={giulioSiniscalco} alt={'landscape'} className={speakerStyle.speakerImg}/>
                </div>
                <div className={speakerStyle.speakerInfoContainer}>
                    <div className={speakerStyle.speakerInfo}>
                        <h3 className={speakerStyle.speakerName}>
                            Fabrizio Ruggiero
                        </h3>
                        <p className={speakerStyle.speakerP}>
                            Direttore Generale e Amministratore Delegato di Edenred Italia. Laureato presso l’Università
                            la Sapienza di Roma in Economia Aziendale e con un Master in Business Administration al MIP,
                            vanta importanti esperienze nei settori “Leisure, Travel & Transportation” e “Business &
                            Financial Services”. Inizia la sua carriera come Consulente prima in Accenture e
                            successivamente in Bain & Company per poi approdare nel 2011 in Europcar Mobility Group come
                            Amministratore Delegato e Direttore Generale Italia.
                        </p>
                    </div>
                    <div className={speakerStyle.speakerInfoLeft}>
                        <h3 className={speakerStyle.speakerName}>
                            Giulio Siniscalco
                        </h3>
                        <p className={speakerStyle.speakerP}>
                            Ha iniziato la sua carriera in Arthur Andersen come Auditor e Business Consultant. Dal 2000
                            al 2009 ha lavorato nel Gruppo Carlsberg Italia, prima come Business Controller, poi come
                            responsabile Controllo di gestione. All’interno del Gruppo ha in seguito intrapreso
                            un’esperienza significativa nell’ambito del Trade Marketing e delle vendite nel ruolo di
                            responsabile Acquisti & Trade Marketing per poi diventare On Trade & Commercial Operation
                            Director. Nell’ottobre 2009 è approdato in Edenred, prima come Direttore Network e
                            successivamente ha assunto il ruolo di Commercial Director Benefit & Engagement che ricopre
                            attualmente.
                        </p>
                    </div>
                </div>


                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3vh'}}>
                    <h1 className={mainStyle.bigH1}>...e tanti altri ospiti d’eccezione attesi</h1>
                </div>

                {/* SEZIONE AGENDA */}
                <Agenda></Agenda>

                {/* SEZIONE LOCATION */}
                <div className={speakerStyle.mainContainer}>
                    <div className={speakerStyle.header}>
                        <div className={speakerStyle.headerTitle}>
                            <h1 className={speakerStyle.titleText}>
                                Location
                            </h1>
                        </div>
                        <img src={landscapeRed} alt={'landscape'} className={speakerStyle.landscape}/>
                    </div>
                </div>

                <div className={locationStyle.mainContainer}>

                    <div className={locationStyle.leftPanel}>
                        <img src={locationImg1} className={locationStyle.img1} alt={'location img'}/>
                        <img src={locationImg2} className={locationStyle.img2} alt={'location img 2'}/>
                    </div>
                    <div className={locationStyle.rightPanel}>
                        <h2 className={locationStyle.subTitle}
                            style={{fontWeight: 'normal', textAlign: 'left', marginBottom: '0'}}>
                            Forum Theatre
                        </h2>
                        <h1 className={locationStyle.title} style={{textAlign: 'left', marginTop: '0'}}>
                            Una location immersiva, ricca di storia
                        </h1>
                        <p className={locationStyle.paragraph} >
                            Il Forum Theatre, situato nel cuore del quartiere Parioli, nei piani sotterranei della Basilica del Sacro Cuore, è stato inaugurato negli anni '50 e ha ospitato star, registi e celebrità internazionali. Oggi, la location, recentemente ristrutturata, offre una tecnologia immersiva all'avanguardia.
                        </p>
                        <p>
                            Adiacenti al teatro, i Forum Studios, fondati da Ennio Morricone, Armando Trovajoli, Luis Bacalov e Piero Piccioni, sono diventati un punto di riferimento nella discografia italiana. Hanno accolto artisti come Fabrizio De Andrè e prodotto colonne sonore iconiche come "Ultimo tango a Parigi" e "Febbre da cavallo".
                        </p>
                    </div>
                </div>

                <div className={locationStyle.mainContainer}>
                        <img src={locationImg2} className={locationStyle.img2Responsive} alt={'location img 2'}/>
                </div>

                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3vh'}}>
                    <CustomButton
                        onClick={scrollToForm}
                    >
                        Registrati
                    </CustomButton>
                </div>
                {/* SPONSORS */}
                {/*<Sponsors></Sponsors>*/}

                {/* FOOTER */}
                <Footer></Footer>
            </div>
        </div>
    )
}

export default Home;